import * as React from 'react'
import { ICatalogConfig } from '../Catalog'
import css from './Navigation.scss'
import { has } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import { faMinus, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FullScreenMobileModal from '../../../../Common/Modal/FullScreenMobileModal'
import SortByDropdown from '../Products/Sort/SortByDropdown'
import { IProductsSortOption } from '../Products/Sort/Sort'
import { RefObject, useEffect, useRef, useState } from 'react'
import useOnScreen from '../../../../Utils/hooks/useOnScreen'
import ColorSwatch from './ColorSwatch'
import LocationContext, { ILocationContextProviderProps } from '../../../Context/LocationContext'
import ChangeMyStoreModal from '../../Search/ChangeMyStoreModal'

export interface ICatalogNavigationItem {
  label: string
  code: string
  values: ICatalogNavigationItemValue[]
}
export interface ICatalogNavigationItemValue {
  value: string
  code?: string
  count: number
}

export interface IFilterItem {
  title: string
  key: string
  value: string
}

export interface IProps {
  attributes: ICatalogNavigationItem[]
  category: any
  configuration: ICatalogConfig
  attributeLimit?: number
  filtersList?: IFilterItem[]
  productSortConfiguration?: {
    options: IProductsSortOption[]
    active: string
  }
}

const CatalogNavigation: React.FC<IProps> = props => {
  const { myClosestStore } = React.useContext(LocationContext.Context) as ILocationContextProviderProps

  const [toggledStates, setToggledStates] = useState<{ [x: string]: any }>({})
  const [toggledAccordion, setToggledAccordion] = useState<{ [x: string]: any }>({})
  const [mobileShowFilters, setMobileShowFilters] = useState(false)
  const [isPromoPage, setIsPromoPage] = useState(false)
  const [showChangeMyStoreModal, setShowChangeMyStoreModal] = useState(false)

  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const containerVisible = useOnScreen(containerRef)

  useEffect(() => {
    if (window?.location.pathname === '/promotions') {
      setIsPromoPage(true)
    }
  }, [])

  useEffect(() => {
    const localState: { [x: string]: any } = {}
    if (props.filtersList && props.filtersList.length > 0) {
      props.filtersList.forEach((filter: IFilterItem) => {
        const attribute = props.attributes.find(attr => attr.label === filter.title)
        if (attribute) {
          localState[attribute.code] = true
        }
      })
    }
    setToggledStates(localState)
    setToggledAccordion(localState)
  }, [props.filtersList])

  const handleShowFilters = () => {
    setMobileShowFilters(true)
  }

  const setToggleState = (section: string) => {
    const states = {
      ...toggledStates,
    }
    states[section] = !states[section]
    setToggledStates(states)
  }

  const mobileToggleFiltersView = (evt: any) => {
    evt.preventDefault()
    setMobileShowFilters(!mobileShowFilters)
  }

  const toggleFiltersOpen = (section: string) => {
    const states = {
      ...toggledAccordion,
    }
    states[section] = !states[section]
    setToggledAccordion(states)
  }

  const onAttributeClickEvent = (query: string, label: string) => {
    const { configuration } = props
    if (configuration.events && configuration.events.onAttributeFilterClick) {
      configuration.events.onAttributeFilterClick(query, label)
      return
    }
  }

  const renderAttribute = (item: ICatalogNavigationItemValue, section: ICatalogNavigationItem) => {
    const query = `${section.code}=${item.value}`

    const isFilterMatch = (filterValue: string, expectedValue: string) => {
      return filterValue.replace(/["']/g, '') === expectedValue
    }

    const isChecked = () => {
      return props.filtersList?.some(filter => {
        const { key, value } = filter
        const cleanValue = value.replace(/["']/g, '')

        return (
          (key === `${item.code}_uFilter` && cleanValue === item.value) ||
          (isFilterMatch(cleanValue, 'In Stock') && item.value === 'In Stock') ||
          (isFilterMatch(cleanValue, 'Free Delivery') && item.value === 'Free Delivery') ||
          (isFilterMatch(cleanValue, 'Free Shipping') && item.value === 'Free Shipping') ||
          (isFilterMatch(cleanValue, 'Online Only') && item.value === 'Online Only')
        )
      })
    }

    return (
      <label className={css.facetContainer}>
        {item.code !== 'COLOR_FAMILY' && (
          <input
            className={css.facetCheckbox}
            type="checkbox"
            checked={isChecked()}
            onChange={() => onAttributeClickEvent(query, section.label)}
          />
        )}
        {item.code === 'COLOR_FAMILY' && (
          <>
            {(item.value === 'Multi' || item.value === 'Other') && (
              <ColorSwatch
                isBright
                type="multi"
                item={item}
                isChecked={isChecked}
                onAttributeClickEvent={() => onAttributeClickEvent(query, section.label)}
                query={query}
                section={section}
              />
            )}
            {item.value !== 'Custom Order' && item.value !== 'Multi' && item.value !== 'Other' && (
              <ColorSwatch
                isBright={item.value === 'White'}
                type="single"
                item={item}
                isChecked={isChecked}
                onAttributeClickEvent={() => onAttributeClickEvent(query, section.label)}
                query={query}
                section={section}
              />
            )}
            {item.value === 'Custom Order' && (
              <ColorSwatch
                isBright={false}
                type="custom"
                item={item}
                isChecked={isChecked}
                onAttributeClickEvent={() => onAttributeClickEvent(query, section.label)}
                query={query}
                section={section}
              />
            )}
            <a onClick={() => onAttributeClickEvent(query, section.label)} className={css.facetValue}>
              {item.value.toLowerCase()} {item.count ? `(${item.count})` : ''}
            </a>
          </>
        )}
        {item.code !== 'COLOR_FAMILY' && (
          <a className={css.facetValue}>
            {item.value.toLowerCase()} {item.count ? `(${item.count})` : ''}
          </a>
        )}
      </label>
    )
  }

  const onClearFilter = (key: string, value?: string) => {
    const { category } = props
    const onRemoveFilter: any = has(props, ['configuration', 'events', 'onRemoveFilter'])
    if (onRemoveFilter) {
      onRemoveFilter(key, category, value)
    }
  }

  const renderActiveFilters = (filters: IFilterItem[]) => {
    return (
      <div>
        <div className={css.clearFiltersButton}>
          <a onClick={() => onClearFilter('all')}>Clear filters</a>
        </div>
        <div className={css.filtersActiveContainerMobile}>
          {filters.map((filter: IFilterItem, index: number) => {
            return (
              <div key={index} className={css.filterContainer}>
                <div>
                  <b>{filter.title}:</b>
                  <span className={css.filterValue}>
                    {filter.value
                      .toLowerCase()
                      .replace('\\', '')
                      .replace(/^"|"$/g, '')}
                  </span>
                </div>
                <div className={css.removeFilter} onClick={() => onClearFilter(filter.key, filter.value)}>
                  <FontAwesomeIcon icon={faTimesCircle} className={css.removeFilter} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderMobileAttributesBody = (attributesCategory: ICatalogNavigationItem[]) => {
    const limit: number = props.attributeLimit || 8
    if (attributesCategory.length === 0) {
      return <div>Not found...</div>
    }

    return attributesCategory.map((attributeCategory: ICatalogNavigationItem, topLevelIndex: number) => {
      const isSectionOpen: boolean = toggledAccordion[attributeCategory.code]
      const isSectionExpanded: boolean = toggledStates[attributeCategory.code]
      const filteredItems: ICatalogNavigationItemValue[] = attributeCategory.values
      return (
        <div key={topLevelIndex} className={css.marginMe}>
          <div className={css.categoryItemHeader} onClick={() => toggleFiltersOpen(attributeCategory.code)}>
            {attributeCategory.label}
            {!isSectionOpen && (
              <span className={`${css.plusIcon}`}>
                <FontAwesomeIcon icon={faPlus} className={css.shopByIcon} />
              </span>
            )}
            {isSectionOpen && (
              <span className={`${css.minusIcon}`}>
                <FontAwesomeIcon icon={faMinus} className={css.shopByIcon} />
              </span>
            )}
          </div>
          {isSectionOpen && (
            <div className={css.sectionHidden}>
              {filteredItems.map((item: ICatalogNavigationItemValue, index) => {
                return (
                  <div
                    key={`${item.value}-${index}`}
                    className={`${index + 1 > limit && !isSectionExpanded ? css.hidden : css.shown} `}
                  >
                    {renderAttribute(item, attributeCategory)}
                  </div>
                )
              })}
            </div>
          )}
          {isSectionOpen && filteredItems.length > limit && (
            <div
              className={`${css.toggleVisibilityButton} ${!isSectionOpen ? css.sectionHidden : css.categoryItemBody}`}
              onClick={() => setToggleState(attributeCategory.code)}
            >
              {isSectionExpanded ? (
                <React.Fragment>Show Less</React.Fragment>
              ) : (
                <React.Fragment>Show More</React.Fragment>
              )}
            </div>
          )}
        </div>
      )
    })
  }

  const onFiltersChange = (option: IProductsSortOption): void => {
    const onSortChange: any = has(props, ['configuration', 'events', 'onProductSort'])
    if (onSortChange) {
      onSortChange(option)
    }
  }

  return (
    <div className={css.attributesContainer}>
      <div className={css.displayedAtFilter}>
        <div className={css.displayedAtLabel}>
          {myClosestStore?.name ? <div>Displayed at: </div> : <div>No store near you</div>}
          <div onClick={() => setShowChangeMyStoreModal(true)} className={css.store}>
            {myClosestStore?.name || 'Look for a store'}
          </div>
        </div>
      </div>
      <div onClick={mobileToggleFiltersView}>
        <b className={css.categoryTopHeader}>
          Shop By
          <span className={`${css.mobileShopByIcon} ${!mobileShowFilters && css.mobilePlusIcon}`}>
            <FontAwesomeIcon icon={faPlus} className={css.shopByIcon} />
          </span>
          <span className={`${css.mobileShopByIcon} ${mobileShowFilters && css.mobileMinusIcon}`}>
            <FontAwesomeIcon icon={faMinus} className={css.shopByIcon} />
          </span>
        </b>
        <hr
          className={`${mobileShowFilters ? css.hrVisible : css.hrNotVisible}`}
          style={{
            marginBottom: '0',
          }}
        />
      </div>
      <div ref={containerRef} className={css.mobileButtonContainer}>
        <div className={css.filterByButton} onClick={handleShowFilters}>
          <b>Filter By</b>
        </div>
        <SortByDropdown productSortConfiguration={props.productSortConfiguration} onChange={onFiltersChange} />
      </div>
      <div className={`${css.categories} ${mobileShowFilters && css.filtersOpen}`}>
        {renderMobileAttributesBody(props.attributes)}
      </div>
      {mobileShowFilters && (
        <FullScreenMobileModal
          onClose={() => {
            setMobileShowFilters(false)
            window.scrollTo(0, 200)
          }}
          headerLabel="Filter By:"
        >
          <div className={css.filtersContainer}>
            <div>
              {props.filtersList && props.filtersList.length > 0 && (
                <div className={css.activeFiltersModal}>{renderActiveFilters(props.filtersList)}</div>
              )}
              <div style={{ height: '100%' }}>{renderMobileAttributesBody(props.attributes)}</div>
            </div>
            <div
              className={css.applyFiltersButton}
              onClick={() => {
                setMobileShowFilters(false)
                window.scrollTo(0, 200)
              }}
            >
              Apply Filters
            </div>
          </div>
        </FullScreenMobileModal>
      )}
      {!containerVisible && !isPromoPage && (
        <div className={css.mobileButtonContainerSticky}>
          <div className={css.filterByButton} onClick={handleShowFilters}>
            <b>Filter By</b>
          </div>
          <SortByDropdown
            productSortConfiguration={props.productSortConfiguration}
            onChange={onFiltersChange}
            style={{ width: '45%' }}
          />
        </div>
      )}
      {showChangeMyStoreModal && <ChangeMyStoreModal onClose={() => setShowChangeMyStoreModal(false)} />}
    </div>
  )
}

export default CatalogNavigation
